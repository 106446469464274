import {useMatches, useParams} from '@remix-run/react';

import {SocialMediaLinks} from '~/gql/generated';
import {Theme} from '~/modules/Expert/data';
import {ThemeContext} from '~/modules/Bio/context/ThemeContext';
import {useContext} from 'react';

export function useExpert() {
  const params = useParams();
  const [root] = useMatches();
  const isSubdomain = !params.expertName;
  const expertSlug = params.expertName || root?.data?.subdomain;
  const homePath = isSubdomain ? '/' : `/${expertSlug}`;
  const {theme: apiTheme, setTheme} = useContext(ThemeContext);
  const setLocalTheme = (nextTheme: Theme) => {
    setTheme && setTheme(nextTheme);
  };

  return {
    expertSlug,
    expertName: params.expertName || root?.data?.subdomain,
    isSubdomain,
    store: apiTheme?.store || {},
    homePath,
    theme: (apiTheme || {}) as Theme,
    expertNav: root?.data?.expertNav,
    socialMedia: root?.data?.socialMedia as SocialMediaLinks,
    setLocalTheme,
  };
}
