import {Path, useMatches} from '@remix-run/react';

import {MergedProduct} from '~/modules/Product/context/types';
import {useExpert} from './useExpert';

export const useExpertLink = (
  link: string | Partial<Path>,
  useFullDomain?: boolean,
): string => {
  const [root] = useMatches();
  const {expertSlug, isSubdomain} = useExpert();
  const selectedLocale = root.data?.selectedLocale;

  let toWithLocale = link;
  let toWithExpert = link;

  if (
    typeof link === 'string' &&
    !link.startsWith('http') &&
    !isSubdomain &&
    !(link.startsWith(expertSlug) || link.startsWith(`/${expertSlug}`))
  ) {
    toWithLocale = selectedLocale
      ? `${selectedLocale.pathPrefix || ''}${link}`
      : link;
    toWithExpert = expertSlug ? `/${expertSlug}${toWithLocale}` : toWithLocale;
  }

  return useFullDomain
    ? `https://${root.data.domain}` + toWithExpert
    : toWithExpert;
};

export const useExpertProductLink = (
  product: MergedProduct,
  isLander?: boolean,
  useFullDomain?: boolean,
  isPreview?: boolean,
): string => {
  const base = useExpertLink('/', useFullDomain);
  return `${base}${getProductPath(product, isLander, isPreview)}`;
};

export function getProductPath(
  product: MergedProduct,
  isLander?: boolean,
  isPreview?: boolean,
) {
  return `${isLander ? 'lander' : 'products'}${getProductPathPostFix(product)}${
    isPreview ? '?preview=true' : ''
  }`;
}

export function getProductPathPostFix(product: MergedProduct) {
  return `${product?.brand?.handle ? `/${product.brand.handle}` : ''}/${
    product?.id
  }`;
}

export function getProductDashboardPath(product: MergedProduct) {
  return `/my/content/edit/${product.id}${
    product.brand?.handle ? `/${product.brand.handle}` : ''
  }`;
}

export function getProductApiPath(expertName: string, product: MergedProduct) {
  return `/api/product/${expertName}/${product.id}${
    product.brand?.handle ? `/${product.brand.handle}` : ''
  }`;
}
