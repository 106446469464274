import * as DOMPurify from 'dompurify';

import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Theme} from '~/modules/Expert/data';
import {useLocalStorage} from 'react-use';
import {useMatches} from '@remix-run/react';

interface ThemeContextProps {
  children: ReactNode;
  theme?: Theme | null;
}

export const ThemeContext = createContext<{
  theme: Theme | null;
  setTheme?: (theme: Theme) => void;
}>({theme: null});

export const ThemeProvider: FC<ThemeContextProps> = (props) => {
  const [root] = useMatches();
  const apiTheme = useMemo((): Theme => {
    const obj =
      typeof root.data?.theme === 'string'
        ? JSON.parse(root.data?.theme || '{}')
        : root.data?.theme;
    if (Object.keys(obj || {}).length > 0) {
      return typeof window !== 'undefined'
        ? (deepSanitizeObject(obj) as Theme)
        : obj;
    }
    return {} as Theme;
  }, [root.data?.theme]);
  const [localStorageTheme, setLocalStorageTheme] = useLocalStorage(
    'wayward_theme',
    apiTheme,
  );
  const [theme, setStateTheme] = useState(apiTheme);

  const setTheme = useCallback(
    (nextTheme: Theme) => {
      nextTheme._version = (nextTheme._version || 0) + 1;
      setStateTheme(nextTheme);
      setLocalStorageTheme(nextTheme);
    },
    [setLocalStorageTheme],
  );

  useEffect(() => {
    const cleanup = (e) => {
      if (e.key === 'wayward_theme') {
        const next = JSON.parse(e.newValue);
        if (theme?._version < next?._version) {
          setStateTheme(next);
          setLocalStorageTheme(next);
        }
      }
    };
    window.addEventListener('storage', cleanup, false);
    return () => {
      window.removeEventListener('storage', cleanup);
    };
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

function deepSanitizeObject(obj: Record<string, unknown>) {
  const newObj = {...obj};
  Object.keys(newObj).forEach((key) => {
    if (typeof newObj[key] === 'string') {
      newObj[key] = DOMPurify.sanitize(newObj[key] as string);
    } else if (Array.isArray(newObj[key])) {
      newObj[key] = (newObj[key] as string[]).map((item) =>
        typeof item === 'string'
          ? DOMPurify.sanitize(item)
          : deepSanitizeObject(item),
      );
    } else if (typeof newObj[key] === 'object') {
      newObj[key] = deepSanitizeObject(newObj[key] as Record<string, unknown>);
    }
  });
  return newObj;
}
